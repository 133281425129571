<div class="auth_dashboard_block">
    <div class="bg_block">
        <div class="white_block">
            <app-header *ngIf="globals.authData!=null"></app-header>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <!-- <div class="right_links">
                            <button *ngIf="administrator_present" class="link_button" (click)="goToDirectAdmin()"><img
                                    src="{{globals.cndURL}}assets/images/administrator.png" alt=""
                                    class="img-fluid" />Adminstration</button>
                            <button *ngIf="instituteList.length==0" class="link_button"><a
                                    href="{{globals.connectorUrl}}/organization-registration"><img
                                        src="{{globals.cndURL}}assets/images/org.png" alt=""
                                        class="img-fluid" />Register New Organization</a></button>
                            <button *ngIf="role_present==1 && instituteList.length>0" class="link_button"
                                (click)="changeRole(2)"><img src="{{globals.cndURL}}assets/images/org.png" alt=""
                                    class="img-fluid" />Access as Organization</button>
                            <button *ngIf="role_present==2 && (covid_present || dcm_indi_present)" class="link_button" (click)="changeRole(1)"><img src="{{globals.cndURL}}assets/images/indi.png" alt=""
                                    class="img-fluid" />Access as Individual</button>
                            <button *ngIf="role_present==2" class="link_button" (click)="changeRole(1)"><img
                                    src="{{globals.cndURL}}assets/images/indi.png" alt="" class="img-fluid" />Access as
                                Individual</button>
                        </div> -->
                        <div class="auth_main_block">
                            <div class="inner_valign">
                                <p *ngIf="highest_role_value == 5 && !connector_present">Coming Soon</p>
                                <button id="help_btn" class="all_btn themeoption_btn float-end help_btn"
                                    (click)="help()">
                                    Tour Guide <i class="fa fa-info-circle ms-2"></i>

                                </button>
                                <div class="individual_block" *ngIf="role_present==1 && highest_role_value != 5">
                                    <h1>Your Available Assessment Tools</h1>
                                    <p class="padding_15">
                                        Below are two assessment tools available for administrators, cohorts, and division heads to manage their users, subscriptions, and additional key functions.</p>
                                    <div class="row justify-content-center ms-1 me-1">
                                        <div *ngIf="false"
                                            class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex">
                                            <div class="auth_block_design" data-title="As an Individual"
                                                data-intro='This section shows you the products that you have purchased or offered to you complimentarily.'>
                                                <h4>As an Individual</h4>
                                                <div class="row justify-content-center">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6 d-flex"
                                                        *ngIf="covid_present">
                                                        <div class="product_block" data-title="COVID"
                                                            data-intro='This is where you would get to know more about self-assessment on COVID-19 including how to access the complimentary assessment.'>
                                                            <h5>COVID</h5>
                                                            <div class="img_block"><img
                                                                    src="{{globals.cndURL}}assets/images/covid.png"
                                                                    alt="" class="img-fluid" /></div>
                                                            <p class="content_block"><span class="mb-3 d-block">This
                                                                    self-assessment for COVID-19 is designed to measure
                                                                    your overall knowledge, skills, and abilities on two
                                                                    domains of practice: Patient and Practice Management
                                                                    and Professional Concepts. This assessment is a
                                                                    targeted assessment of the competencies required in
                                                                    subdomains of the IFMA Competency Framework
                                                                    specifically related to COVID-19.</span>
                                                                To start/resume your assessment, click on "View".
                                                            </p>
                                                            <div class="button_block"><button
                                                                    (click)="viewProduct(2,5,null)" class="buy_link"
                                                                    data-intro='Click View to start a new or resume an in-progress self-assessment for COVID-19.'>View</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6 d-flex"
                                                        *ngIf="dcm_indi_present">
                                                        <div class="product_block" data-title="Self Assessment Tool"
                                                            data-intro='This is where you would get to know more about self-assessment on Competency Model, how to buy a license and give an assessment.'>
                                                            <h5>Self Assessment Tool</h5>
                                                            <div class="img_block"><img
                                                                    src="{{globals.cndURL}}assets/images/dcm.png" alt=""
                                                                    class="img-fluid" /></div>
                                                            <p class="content_block">
                                                                <span class="mb-3 d-block">The Self Assessment Tool
                                                                    consists of a Personality Assessment and a
                                                                    Competency Assessment. The information you provide
                                                                    will help to measure your proficiency level in the
                                                                    competency domains of medical-surgical nurses. This
                                                                    assessment of your competency will demonstrate your
                                                                    strengths and highlight opportunities for further
                                                                    learning and development. </span>
                                                                <span class="mb-3 d-block">To start/resume your
                                                                    assessment, click on "View". <br>
                                                                    To buy a new/additional license, click on "Buy
                                                                    License".</span>
                                                                <!-- <strong>Available
                                                                    Licenses:</strong>{{dcm_ind_licenses}} -->
                                                            </p>
                                                            <div class="button_block">
                                                                <!-- <button *ngIf="dcm_ind_total_licenses > 0"
                                                                    (click)="viewProduct(3,5,null)"
                                                                    data-intro="Click this button to start a new or resume an in-progress competency assessment."
                                                                    class="buy_link two_btn">View</button> -->
                                                                <a (click)="buyIndLic()"
                                                                    data-intro="Click this button to buy your first or an additional license for competency assessment."
                                                                    class="buy_link two_btn">
                                                                    <span *ngIf="dcm_ind_total_licenses == 0">Buy
                                                                        License</span>
                                                                    <span *ngIf="dcm_ind_total_licenses > 0">Buy
                                                                        More</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex">
                                            <div class="auth_block_design" data-title="As an Organization"
                                                data-intro='This section displays products that assist you in managing organization and/or assessments.'>
                                                <h4>As an Organization</h4>
                                                <div class="row justify-content-center">
                                                    <div
                                                        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6 d-flex">
                                                        <div class="product_block" data-title="Connector"
                                                            data-intro='The connector is effective for managing organizational tasks, including users, subscriptions, cohorts, and divisions.'>
                                                            <h5>Connector</h5>
                                                            <div class="img_block"><img
                                                                    src="{{globals.cndURL}}assets/images/connector.png"
                                                                    alt="" class="img-fluid" /></div>
                                                            <p class="content_block">
                                                                <span class="mb-3 d-block">Manage your organization more effectively with this tool, it allows you to efficiently handle users, cohorts, divisions, subscriptions, and more.</span>
                                                                Click 'View' to access organization management.
                                                                
                                                                <!-- <br>To register a new organization, click on
                                                                "Register". -->
                                                            </p>
                                                            <div class="button_block">

                                                                <!-- *ngIf="connector_present || (highest_role_value == 1 || highest_role_value == 2)" -->
                                                                <button
                                                                    (click)="goToDirectConnector($event)"
                                                                    class="buy_link two_btn"
                                                                    data-intro="Click ‘View’ to proceed with organization management.">View</button>
                                                                <!-- <button
                                                                    *ngIf="connector_present || (highest_role_value == 1 || highest_role_value == 2)"
                                                                    (click)="goToDirectConnector('organization-registration')"
                                                                    data-intro="Click this button to register your new organization and become a Primary Contact."
                                                                    class="buy_link two_btn">Register new</button>
                                                                <button
                                                                    *ngIf="!connector_present && !(highest_role_value == 1 || highest_role_value == 2)"
                                                                    (click)="registerOrg()"
                                                                    data-intro="Click this button to register your new organization and become a Primary Contact."
                                                                    class="buy_link">Register</button> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="administrator_present || (highest_role_value == 1 || highest_role_value == 2)"
                                                        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6 d-flex">
                                                        <div class="product_block" data-title="Self Assessment Tool"
                                                            data-intro='The self-assessment tool is effective for managing assessments and assessment-related details.'>
                                                            <h5>Self Assessment Tool</h5>
                                                            <div class="img_block"><img
                                                                    src="{{globals.cndURL}}assets/images/dcm.png" alt=""
                                                                    class="img-fluid" /></div>
                                                            <p class="content_block"><span class="mb-3 d-block">The Self-Assessment Tool aims to empower users to take ownership of their professional development by taking an assessment. It allows users to evaluate their own competency and knowledge levels across various domains.</span>
                                                                <span class="mb-3 d-block">To start/resume your assessment, click on ‘View’.<br>
                                                                    As an Administrator, use the 'Administration' option to handle assessment-related management. <br>
                                                                    <!-- To buy a new/additional license pack, click on "Buy
                                                                    License". -->
                                                                </span>
                                                                <!-- <strong>Available
                                                                    Licenses:</strong>{{dcm_org_licenses}} <span
                                                                    *ngIf="dcm_admin_org>1">in
                                                                    {{dcm_admin_org}} organizations </span> -->
                                                            </p>
                                                            <div class="button_block">
                                                                <!-- <button (click)="goToDirectDCM()"
                                                                    [ngClass]="{'three_btn':highest_role_value == 6}"
                                                                    class="buy_link two_btn"
                                                                    data-intro="Click this button to start a new or resume an in-progress competency assessment that have been assigned to you by your organization.">View</button> -->
                                                                <button (click)="goToDirectAdmin()"
                                                                    class="buy_link two_btn "
                                                                    data-intro="Click on ‘Administration’ to administer assessment-related management."
                                                                    [ngClass]="{'three_btn':highest_role_value == 6}">Administration</button>
                                                                <!-- <a *ngIf="highest_role_value == 6" (click)="buyOrgLic()"
                                                                    class="buy_link three_btn"
                                                                    data-intro="Click this button to buy your first or additional license packs for competency assessment.">
                                                                    <span *ngIf="dcm_org_licenses == 0">Buy
                                                                        License</span>
                                                                    <span *ngIf="dcm_org_licenses > 0">Buy
                                                                        More</span>
                                                                </a> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="organization_block" *ngIf="role_present==2 && instituteList.length>0">
                                    <h1>Your organizations</h1>
                                    <p class="padding_30">This is the placeholder for organization details.</p>
                                    <div class="row justify-content-center ms-1 me-1">
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 d-flex">
                                            <div class="institute_block register_new_block">
                                                <div class="content_block">
                                                    <p>Register your own organization and become a Primary Contact </p>
                                                    <div class="clearfix"></div>
                                                </div>
                                                <div class="button_block">
                                                    <a href="{{globals.connectorUrl}}/organization-registration"
                                                        class="buy_link">Register
                                                        Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center ms-1 me-1">
                                        <ng-container *ngFor="let insti of instituteList; let i=index;">
                                            <div
                                                class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 d-flex border_line">
                                                <div class="institute_block">
                                                    <div class="content_block">
                                                        <div class="address_phone">
                                                            <div class="id_box float-start me-3"
                                                                *ngIf="insti.provider_id">{{insti.provider_id}}
                                                            </div>
                                                            <h5>{{insti.institute_name}}</h5>
                                                            <div class="clearfix"></div>
                                                            <p class="system_name" *ngIf="insti.system">
                                                                <b>System:</b>{{insti.system.system}}
                                                            </p>
                                                            <p class="system_name" *ngIf="!insti.system">
                                                                <b>System:</b>Not Affiliated
                                                            </p>
                                                            <p><i class="fa fa-map-marker"></i>
                                                                <span>{{insti.address1}}<br>{{insti.city}},

                                                                    {{insti.states.state_abbreviation}}
                                                                    {{insti.zipcode}}</span>
                                                            </p>

                                                            <p><i class="fa fa-phone"></i>
                                                                <span *ngIf="insti.phone_number">{{insti.phone_number |
                                                                    phone}}</span>
                                                                <span *ngIf="!insti.phone_number">NA</span>
                                                            </p>
                                                            <p>
                                                                <i class="fa fa-list-ul"></i>

                                                                <span
                                                                    *ngIf="insti.institute_types">{{insti.institute_types.display_text}}</span>
                                                                <span *ngIf="!insti.institute_types">NA</span>

                                                                <!-- <span class="no_border d-none d-xxl-block"><i
                                                                    class="fa fa-user"></i>
                                                                <span *ngIf="insti.institute_owners"
                                                                    class="no_border">{{insti.institute_owners.display_text}}</span>
                                                                <span *ngIf="!insti.institute_owners"
                                                                    class="no_border">NA</span>
                                                            </span> -->
                                                            </p>
                                                            <p><i class="fa fa-user"></i>
                                                                <span
                                                                    *ngIf="insti.institute_owners">{{insti.institute_owners.display_text}}</span>
                                                                <span *ngIf="!insti.institute_owners">NA</span>
                                                            </p>
                                                        </div>

                                                        <!-- <div class="active_user_block">Divisions<b>{{insti.total_divisions}}</b></div>
                                                        <div class="active_user_block">Units<b>{{insti.total_units}}</b>
                                                        </div>
                                                        <div class="active_user_block">Users<b>{{insti.total_users}}</b>
                                                        </div> -->

                                                        <div class="clearfix"></div>
                                                    </div>
                                                    <!-- <div class="role_block">
                                                            Role: <b>{{insti.Role}}</b>
                                                    </div> -->
                                                    <div class="product_list_block" kendoTooltip>
                                                        <div class="float-start" *ngIf="insti.admin_present">
                                                            <button (click)="goToAdmin(insti)" title="Adminstrator"><img
                                                                    src="{{globals.cndURL}}assets/images/administrator.png"
                                                                    alt="" class="img-fluid" /></button>
                                                        </div>
                                                        <div class="float-end"
                                                            *ngFor="let product of insti.product_list; let j=index;">
                                                            <button (click)="viewProduct(1,product.role_value,insti.id)"
                                                                title="Connector" *ngIf="product.product.value==1"><img
                                                                    src="{{globals.cndURL}}assets/images/connector.png"
                                                                    alt="" class="img-fluid" /></button>
                                                            <button (click)="viewProduct(3,product.role_value,insti.id)"
                                                                *ngIf="product.product.value==3"
                                                                title="Self Assessment Tool"><img
                                                                    src="{{globals.cndURL}}assets/images/dcm.png" alt=""
                                                                    class="img-fluid" /></button>
                                                        </div>
                                                        <div class="clearfix"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <!-- <ng-container
                                    *ngIf="(!covid_present && role_present==1) || ((!dcm_indi_present && role_present==1) || (!dcm_present && role_present==2)) || (!connector_present && role_present==2)">
                                    <hr *ngIf="(covid_present || dcm_indi_present)">
                                    <div class="clearfix"></div>
                                    <div class="interested_block">
                                        <h4 *ngIf="(covid_present || dcm_indi_present)">You might be interested in</h4>
                                        <h1 *ngIf="(!covid_present && !dcm_indi_present)">Buy a Product as an Individual
                                        </h1>
                                        <p class="padding_15">Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry.<br>Lorem Ipsum is simply dummy text.</p>
                                        <div class="row justify-content-center ms-1 me-1">

                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 d-flex"
                                                *ngIf="!covid_present && role_present==1">
                                                <div class="product_block">
                                                    <h5>COVID</h5>
                                                    <div class="img_block"><img
                                                            src="{{globals.cndURL}}assets/images/covid.png" alt=""
                                                            class="img-fluid" /></div>
                                                    <p class="content_block">Lorem Ipsum is simply dummy text of the
                                                        printing and typesetting industry. Lorem Ipsum is simply dummy
                                                        text.
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting
                                                        industry. Lorem Ipsum is simply dummy text.</p>
                                                    <div class="button_block"><button class="buy_link">Buy Now</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 d-flex"
                                                *ngIf="(!dcm_indi_present && role_present==1) || (!dcm_present && role_present==2)">
                                                <div class="product_block">
                                                    <h5>Digital Competency Framework</h5>
                                                    <div class="img_block"><img
                                                            src="{{globals.cndURL}}assets/images/dcm.png" alt=""
                                                            class="img-fluid" /></div>
                                                    <p class="content_block">Lorem Ipsum is simply dummy text of the
                                                        printing and typesetting industry. Lorem Ipsum is simply dummy
                                                        text.
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting
                                                        industry. Lorem Ipsum is simply dummy text.</p>
                                                    <div class="button_block"><button class="buy_link"><a
                                                                href="https://www.amsn.org/iclp" target="_blank">Buy
                                                                Now</a></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 d-flex"
                                                *ngIf="!connector_present && role_present==2">
                                                <div class="product_block">
                                                    <h5>Connector</h5>
                                                    <div class="img_block"><img
                                                            src="{{globals.cndURL}}assets/images/connector.png" alt=""
                                                            class="img-fluid" /></div>
                                                    <p class="content_block">Lorem Ipsum is simply dummy text of the
                                                        printing and typesetting industry. Lorem Ipsum is simply dummy
                                                        text.
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting
                                                        industry. Lorem Ipsum is simply dummy text.</p>
                                                    <div class="button_block"><button class="buy_link">
                                                            Buy Now</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </ng-container> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer *ngIf="globals.authData!=null"></app-footer>
</div>